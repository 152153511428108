
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiBrandGetDetail,
         apiBrandDetailUpdate,
         apiBrandCreate ,
         apiBrandVersionCreate} from '../services/tpsApi/tpsApiBrand';
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { IBrandDetail } from '../services/tpsApi/models/brand';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
// import OperatingRulesTable from '@/components/OperatingRulesTable.vue';
import WarningModal from '@/components/WarningModal.vue';
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import { apiPaymentRuleGetVersion } from '../services/tpsApi/tpsApiPaymentRule';
import { apiOperationRuleGetVersion } from '../services/tpsApi/tpsApiOperationRule';
//import DatepickerLite from "vue3-datepicker-lite";
import moment from 'moment';
@Options({
  components: {
        //OperatingRulesTable,
        WarningModal,
        //DatepickerLite,
        Datepicker
  },
  emits: ["hide-form"]
})


export default class BrandForm extends Vue {
    $refs!: {
        //table: OperatingRulesTable,
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: IBrandDetail = {} as any;
    formData: IBrandDetail = {} as any;
    ruleVer = {} as any;
    actionType: string = "";
    brandID: number = -1;
    companySelectOptions: {} = {};
    rulesTableVisible: boolean = false;
    nameWarningText: string = "";
    operationVerWarning: string = "";
    paymentVerWarning: string = "";
    programVerWarning: string = "";
    firmwareVerWarning: string = "";
    dateWarningText: string = "";
    PAYMENT_VER_OPTIONS: {} = {};
    OPERATION_VER_OPTIONS: {} = {};
    FIRMWARE_VER_OPTIONS: {} = {};
    READER_VER_OPTIONS: {} = {};
    permission = undefined;

    startdate = {
        id: "startdate",
        class: "md-input myDateInput md-datepicker w-10-rem",
        placeholder: "生效日期",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.ruleVer["version_date"] = value;
        }
    }

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }

    async mounted() {
        this.permission = this.$store.getters.permission;
        this.companySelectOptions = await apiCompanyGetListName();
    }
    handleDate = (modelData) => {
          this.ruleVer["version_date"] = modelData;
    }
    
    async initForm(pActionType, pBrandID) {
        this.actionType = pActionType;
        this.brandID = pBrandID;
        this.ruleVer = {} as any;
        if(pBrandID) {
            this.formData = Object.assign({}, await apiBrandGetDetail({id:pBrandID}));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Default select first item
            this.formData["company_id"] = Number(Object.keys(this.companySelectOptions)[0]);
            this.formData["app_id"] = 1;
            //Default check first item
            this.formData["operation_mode"] = "計程車";
            //Default data
            this.formData["invalid"] = 0;
            this.formData["route_upload"] = 0;
        }
        this.PAYMENT_VER_OPTIONS = await apiPaymentRuleGetVersion({filter_brand:this.brandID});
        this.OPERATION_VER_OPTIONS = await apiOperationRuleGetVersion();
        this.FIRMWARE_VER_OPTIONS = await apiListMenuGetItem({menuName: "卡機", listName: "韌體版本"});
        this.READER_VER_OPTIONS = await apiListMenuGetItem({menuName: "卡機", listName: "軟體版本"});

    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    getPermissionProp(){
        return this.permission === 'admin'?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            (!this.formData["name"])?this.nameWarningText="品牌名稱不能為空白":"";
            if(this.formData["name"]&&this.formData["name"].length<=8) {
                //Set default modified_time & modified_user
                let dt = new Date(); 
                this.formData["modified_time"] = `${
                    (dt.getMonth()+1).toString().padStart(2, '0')}-${
                    dt.getDate().toString().padStart(2, '0')}-${
                    dt.getFullYear().toString().padStart(4, '0')} ${
                    dt.getHours().toString().padStart(2, '0')}:${
                    dt.getMinutes().toString().padStart(2, '0')}:${
                    dt.getSeconds().toString().padStart(2, '0')}`;
                this.formData["modified_user"] = this.$store.getters.currentUser;
                
                if(this.actionType == "update") {
                    let res = await apiBrandDetailUpdate({  id: this.brandID, 
                                                            data: arrangeUpdateData(this.formData,this.originalData)});
                    if(res.msg === ("ok")) this.hideForm(true);
                    else this.hideForm(false);
                }      
                else if(this.actionType == "create") {
                    let res = await apiBrandCreate(this.formData);
                    if(res.msg === ("ok")) this.hideForm(true);
                    else this.hideForm(false);
                }    
            }
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    async saveVersion(pSave: boolean){
        if(pSave) {
            if(!this.ruleVer.payment_version)  this.paymentVerWarning = "請選擇付款參數版本";

            if(!this.ruleVer.operation_rule_version)  this.operationVerWarning = "請選擇營運參數版本";
            if(!this.ruleVer.firmware_version)  this.firmwareVerWarning = "請選擇營運參數版本";
            if(!this.ruleVer.program_version)  this.programVerWarning = "請選擇營運參數版本";

            if(!this.ruleVer.version_date) this.dateWarningText = "請選擇生效日";

            if(this.ruleVer.payment_version && this.ruleVer.operation_rule_version && this.ruleVer.firmware_version && this.ruleVer.program_version && this.ruleVer.version_date){
                this.ruleVer.brand_id = this.brandID;
                let res = await apiBrandVersionCreate({ data: this.ruleVer});
                if(res.msg === ("ok")){ this.ruleVer = {} as any;  this.hideForm(true);}
                else this.hideForm(false);
            }
        }
        
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    
    datepickerActive(pID) {
        let datepicker = document.getElementById(pID)
        if(datepicker) (datepicker as HTMLFormElement).focus(); 
    }

    /* Rules Table */
    // setRulesTableVisible(pShow) {
    //     this.$refs.table.initTable("2");
    //     this.rulesTableVisible = pShow;
    // }
}
