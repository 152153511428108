
import { Options, Vue } from 'vue-class-component';
import { apiBrandGetList,
         apiBrandGetListTotalNum } from '../services/tpsApi/tpsApiBrand';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { IBrandList } from '../services/tpsApi/models/brand';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import BrandForm from '@/components/BrandForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    Table,
    ActionMenu,
    BrandForm,
    Pagination
  },
})
export default class Brand extends Vue {
    $refs!: {
        form: BrandForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        operation_mode: "營運模式",
        name: "品牌名稱",
        id: "品牌編號",
        GUInumber: "統一編號",
        tel: "電話",
        addr: "公司地址", 
        company_id: "所屬公司", 
        app_id: "APP", 
        account_contact: "聯絡人",
        cell_phone: "手機號碼", 
        op_id: "營運規則",
        modified_time: "最後執行時間",
        modified_user: "最後執行人員",
        invalid: "狀態"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    brandList: IBrandList | Blob = [];
    filter: string | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiBrandGetListTotalNum({filter: this.filter});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter});
        this.brandList = await apiBrandGetList(pData);
    } 

    async download(pData: { output: string }) {
        const res = await apiBrandGetList({output: pData.output, filter: this.filter});
        exportFile({ fileName: "品牌資料", data: res });
    }

    doSearch(pData: {filter: string}) {
        this.filter = pData.filter;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }
    
    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    } 
}
